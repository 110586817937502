type AnimationState = {
  entering: (timeout: number) => React.CSSProperties
  entered: (timeout: number) => React.CSSProperties
  exiting: (timeout: number) => React.CSSProperties
  exited: (timeout: number) => React.CSSProperties
}

type AnimationStyles = {
  slide: AnimationState
  fade: AnimationState
  scale: AnimationState
}

export const animationStyles: AnimationStyles = {
  slide: {
    entering: (timeout: number) => ({
      transform: 'translateY(-100%)',
      opacity: 0,
      transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
    }),
    entered: (timeout: number) => ({
      transform: 'translateY(0)',
      opacity: 1,
      transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
    }),
    exiting: (timeout: number) => ({
      transform: 'translateY(0)',
      opacity: 1,
      transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
    }),
    exited: (timeout: number) => ({
      transform: 'translateY(-100%)',
      opacity: 0,
      transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
    }),
  },
  fade: {
    entering: (timeout: number) => ({
      opacity: 0,
      visibility: 'visible',
      transition: `opacity ${timeout}ms ease-in-out`,
    }),
    entered: (timeout: number) => ({
      opacity: 1,
      visibility: 'visible',
      transition: `opacity ${timeout}ms ease-in-out`,
    }),
    exiting: (timeout: number) => ({
      opacity: 1,
      visibility: 'visible',
      transition: `opacity ${timeout}ms ease-in-out`,
    }),
    exited: (timeout: number) => ({
      opacity: 0,
      visibility: 'visible',
      transition: `opacity ${timeout}ms ease-in-out`,
    }),
  },
  scale: {
    entering: (timeout: number) => ({
      transform: 'scale(0.8)',
      opacity: 0,
      transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
    }),
    entered: (timeout: number) => ({
      transform: 'scale(1)',
      opacity: 1,
      transition: `transform ${timeout}ms ease-out, opacity ${timeout}ms ease-out`,
    }),
    exiting: (timeout: number) => ({
      transform: 'scale(1)',
      opacity: 1,
      transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
    }),
    exited: (timeout: number) => ({
      transform: 'scale(0.8)',
      opacity: 0,
      transition: `transform ${timeout}ms ease-in, opacity ${timeout}ms ease-in`,
    }),
  },
}
