import React, { useState, useEffect } from 'react'
import { animationStyles } from './index.styles'

type AnimationState = 'entering' | 'entered' | 'exiting' | 'exited'

type AnimatedComponentProps = {
  isVisible: boolean
  hasStartAnimation: boolean
  hasEndAnimation: boolean
  children: React.ReactNode
  animationType?: 'slide' | 'fade' | 'scale'
  timeout?: number
  onEnd?: () => void
}

const Animation: React.FC<AnimatedComponentProps> = ({
  isVisible,
  hasStartAnimation,
  hasEndAnimation,
  children,
  animationType = 'fade',
  timeout = 300,
  onEnd,
  ...restProps
}) => {
  const [animationState, setAnimationState] = useState<AnimationState>(
    isVisible && !hasStartAnimation ? 'entered' : 'exited',
  )

  useEffect(() => {
    let startTimeout: NodeJS.Timeout
    let endTimeout: NodeJS.Timeout

    // Handle "entering" animation only if hasStartAnimation is true
    if (isVisible && hasStartAnimation) {
      setAnimationState('entering')
      startTimeout = setTimeout(() => {
        setAnimationState('entered')
        if (onEnd) onEnd()
      }, timeout)
    }

    // Handle "exiting" animation only if hasEndAnimation is true
    if (!isVisible && hasEndAnimation) {
      setAnimationState('exiting')
      endTimeout = setTimeout(() => {
        setAnimationState('exited')
        if (onEnd) onEnd()
      }, timeout)
    }

    // If isVisible but no animation needed, go directly to 'entered' state
    if (isVisible && !hasStartAnimation) {
      setAnimationState('entered')
    }

    // Clean up timeouts on component unmount or when dependencies change
    return () => {
      startTimeout && clearTimeout(startTimeout)
      endTimeout && clearTimeout(endTimeout)
    }
  }, [isVisible, animationType, timeout, hasStartAnimation, hasEndAnimation, onEnd])

  // Apply no style change if isVisible is true and no animation is required
  const currentStyle =
    isVisible && !hasStartAnimation
      ? {} // No animation, simply render the content
      : animationStyles[animationType][animationState](timeout)

  return (
    <div style={currentStyle} {...restProps}>
      {children}
    </div>
  )
}

export default Animation
