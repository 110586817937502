import { mergeWith } from 'lodash'
import { capitalize, find, flow, get, isArray } from 'lodash/fp'
import { CUSTOM_DATE_FORMAT } from '@shared/Grid/utils/excelStyles'
import { BLANKS_FILTER_LABEL, stageGateOptions } from '@helpers/constants'
import { dueDateError, validateDueDate } from '@helpers/schemas/utils'
import { agRichKeyCreator, agSelectFilterValueFormatter, getParamsForAgRichSelect } from '@helpers/gridUtils'
import {
  dateComparator,
  datesComparator,
  formatDate,
  getMaxDate,
  getMaxDateWithThreshold,
  getMinDate,
  lastYear,
} from '@shared/Grid/utils/dates'
import { statusComparator } from '@shared/Grid/utils/statusCellUtils'
import {
  statusFilterValueGetter,
  statusValueGetter,
  statusTooltipValueGetter,
  tooltipTheme,
  StatusCellEditor,
  StatusCellRenderer,
  InputCellEditor,
  DateCellEditor,
} from '@imo/imo-ui-toolkit'
import {
  additionalExportColumns,
  customColumnProcessCellCallback,
  getKeyProcessName,
  getProjectName,
} from './exportUtils'
import { listItemTypes, processItemPatterns } from '../constants'
import { basicStatusList } from '@helpers/statuses'
import { booleanYesNoOptions, COLUMN_TYPE } from '@shared/Grid/constants'
import {
  getLinkedInitiative,
  listIdValueSetter,
  valueGetterWithInterdependency,
} from '@shared/DayOne/utils/mapDayOneData'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'
import { itemFields } from '@common/accessController/strategies/constants'
import { getCustomColumnsColumnDefs } from '@shared/Grid/customColumnsColDef'
import { getSpecialAttributesColumn } from '@shared/DayOne/utils/specialAttributesColumn'
import { getRowFieldsValues } from '@shared/Grid/HierarchyFilter/utils'
import HierarchyFilter, { HierarchyFloatingFilter } from '@shared/Grid/HierarchyFilter/HierarchyFilter'
import { DayOneProjectListDto, TeamKeyProcessProjectOrTask } from '@common/types/dtos/DayOneProjectListDto'
import { TFunction } from 'i18next'
import { createOwnerColumnDef } from './owner-column-def'
import { ColDef, RowNode } from 'ag-grid-community'
import {
  categoryTextMatcher,
  confidentialTextMatcher,
  priorityTextMatcher,
} from '@shared/Grid/utils/filterTextMatchers'

const l3ApprovedStageGateId = flow([find({ label: 'L3 Approved' }), get('id')])(stageGateOptions)

/** Check if the given row has been created yet on the backend */
const isProjectPlanRowCreated = ({ data, colDef }: $TSFixMe) => {
  // We want to allow editing the name before the entity
  // is created but nothing else
  if (colDef.field === 'task' || colDef.field === 'project' || colDef.field === 'keyProcess') {
    return true
  }

  return !data?.isNew
}

/** We only want to allow full editing once the row has been created **/
const addRowEditabilityCheck = (columnDefs: ColDef[]) => {
  return columnDefs.map((columnDef) => {
    return {
      ...columnDef,
      editable: (params: any) => {
        if (typeof columnDef.editable === 'boolean') {
          return columnDef.editable && isProjectPlanRowCreated(params)
        } else if (typeof columnDef.editable === 'function') {
          return columnDef.editable(params) && isProjectPlanRowCreated(params)
        }
        return isProjectPlanRowCreated(params)
      },
    }
  })
}

export const doesStatusFilterPass = ({ row, model, selected }: $TSFixMe) => {
  const {
    data,
    node: { parent },
  } = row

  const rowValues = getRowFieldsValues(data, model)

  if (data.type !== listItemTypes.TASK) return Boolean(get(rowValues, selected))

  const parentValues = getRowFieldsValues(parent?.data, model)

  return get(rowValues, selected) && get(parentValues, selected)
}

const getProjectPriorityId = (data: TeamKeyProcessProjectOrTask, node: RowNode) => {
  if (data.type === 'project') {
    return data.priority
  } else if (data.type === 'task') {
    return node?.parent?.data?.priority
  }
  return null
}

export const isEditableField = (params: $TSFixMe) => {
  const {
    data,
    context,
    colDef: { field },
    node,
  } = params

  const fieldItem = Object.values(processItemFields).includes(field) ? field : itemFields.CUSTOM
  const linkedInitiative = getLinkedInitiative(data, context)

  const isPriorityLockingProjectPlan = context?.priorityIdsLockingProjectPlan?.includes(
    getProjectPriorityId(data, node),
  )

  if (
    !data.isNew &&
    context?.isLockProjectPlanEnabled &&
    !context?.isIMOUser &&
    isPriorityLockingProjectPlan &&
    fieldItem !== COLUMN_TYPE.status
  ) {
    const priorityId = getProjectPriorityId(data, node)
    context?.handleFieldLockedByPriority(priorityId)
    return false
  }

  if (
    context.isAdvancedFinancials &&
    fieldItem === COLUMN_TYPE.dueDate &&
    linkedInitiative?.stageGateId >= l3ApprovedStageGateId
  ) {
    return false
  }

  return context.checkPermission(fieldItem, data, context.dayOneData)
}

export const isEditableDueDate = (props: $TSFixMe) => {
  const {
    data: { priority },
    context: { lockDueDateDay1Options, priorityOptions },
  } = props

  const priorityValue = priority ? find({ value: priority }, priorityOptions)?.label : null

  if (isArray(lockDueDateDay1Options) && !lockDueDateDay1Options.includes(priorityValue)) return false

  return isEditableField(props)
}

const listIdArrayMerger = (i1: $TSFixMe, i2: $TSFixMe) => (!!i1 ? parseInt(i1) : i2)

const getFormattedListId = (id: $TSFixMe) => (!!id ? mergeWith(id.split('.'), [0, 0, 0], listIdArrayMerger) : id)

export const listIdComparator = (id1: $TSFixMe, id2: $TSFixMe) => {
  const formattedId1 = getFormattedListId(id1)
  const formattedId2 = getFormattedListId(id2)

  if (!formattedId1 && !formattedId2) return 0

  if (id1 === BLANKS_FILTER_LABEL) return -1

  if (!formattedId1) return -1

  if (!formattedId2) return 1

  if (formattedId1[0] !== formattedId2[0]) return formattedId1[0] - formattedId2[0]

  if (formattedId1[1] !== formattedId2[1]) return formattedId1[1] - formattedId2[1]

  return formattedId1[2] - formattedId2[2]
}

export const getCategoryColumn = (props: $TSFixMe) => ({
  headerName: 'Category',
  field: processItemFields.CATEGORY,
  suppressSizeToFit: true,
  width: 75,

  ...getParamsForAgRichSelect(({ context }: $TSFixMe) => context.categoryOptions, undefined, true),

  cellEditor: 'agRichSelectCellEditor',
  cellEditorPopup: true,
  filter: 'agSetColumnFilter',
  keyCreator: agRichKeyCreator,

  filterParams: {
    debounceMs: 0,
    suppressAndOrCondition: true,
  },

  ...props,
})

const keyProcessProjectTaskColumns = [
  {
    headerName: 'Key process',
    field: 'keyProcess',
    valueGetter: getKeyProcessName(),
    forceExcelIterate: true,
    hide: true,
  },
  {
    headerName: 'Project',
    field: 'project',
    valueGetter: getProjectName({}),
    forceExcelIterate: true,
    hide: true,
  },
  {
    headerName: 'Task',
    field: 'task',
    valueGetter: ({ data }: $TSFixMe) => (data.type === listItemTypes.TASK ? data.name : ''),
    forceExcelIterate: true,
    hide: true,
  },
]

interface DayOneColumnDefsArgs {
  data: DayOneProjectListDto
  customColumns: $TSFixMe
  isAdvancedFinancials: boolean
  showLinkedTSA: boolean
  shouldDisplayProjectMirror: boolean
  isEntityUniqueIdEnabled: boolean
  specialAttributesColumnWidth: number
  isDragAndDropEnabled: boolean
  t: TFunction
  isSMO: boolean
}

export const dayOneColumnDefs = ({
  customColumns,
  isAdvancedFinancials,
  showLinkedTSA,
  shouldDisplayProjectMirror,
  specialAttributesColumnWidth,
  isEntityUniqueIdEnabled,
  isDragAndDropEnabled,
  t,
  isSMO,
}: DayOneColumnDefsArgs): ColDef[] => {
  const columnDefs: ColDef[] = [
    ...keyProcessProjectTaskColumns,
    {
      headerName: 'ID',
      field: processItemFields.PROJECT_LIST_ID,
      width: 55,
      suppressSizeToFit: true,
      cellClass: 'indexCell',
      editable: (params) => {
        if (isDragAndDropEnabled) {
          return false
        }
        return isEditableField(params)
      },
      cellEditor: InputCellEditor,
      cellEditorParams: ({ data }: $TSFixMe) => ({
        pattern: processItemPatterns[data.type],
      }),
      valueSetter: listIdValueSetter,
      comparator: listIdComparator,
      valueGetter: valueGetterWithInterdependency(processItemFields.PROJECT_LIST_ID),
      filter: 'agSetColumnFilter',
    },
    getSpecialAttributesColumn({ showLinkedTSA, shouldDisplayProjectMirror, specialAttributesColumnWidth, t, isSMO }),
    createOwnerColumnDef({ isEditableField }),
    {
      headerName: 'Status',
      cellRenderer: StatusCellRenderer,
      suppressSizeToFit: true,
      width: 60,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      field: processItemFields.STATUS,
      editable: isEditableField,
      tooltipValueGetter: statusTooltipValueGetter,
      valueGetter: statusValueGetter,
      filterValueGetter: statusFilterValueGetter,
      comparator: (value1: $TSFixMe, value2: $TSFixMe) => statusComparator(value1?.label, value2?.label),
      cellEditorParams: {
        values: basicStatusList,
        cellRenderer: StatusCellEditor,
      },
      filter: 'agMultiColumnFilter',
      filterParams: {
        debounceMs: 0,
        suppressAndOrCondition: true,
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: HierarchyFilter,
            floatingFilterComponent: HierarchyFloatingFilter,
            filterParams: {
              model: [
                { field: 'type', values: ['project', 'task'], renderer: ({ value }: $TSFixMe) => capitalize(value) },
                {
                  field: 'status',
                  renderer: StatusCellEditor,
                  comparator: statusComparator,
                },
              ],
              doesFilterPass: doesStatusFilterPass,
              debounceMs: 0,
            },
          },
        ],
      },
    },
    {
      headerName: 'Priority',
      field: processItemFields.PRIORITY,
      suppressSizeToFit: true,
      width: 80,
      filter: 'agMultiColumnFilter',
      cellEditor: 'agRichSelectCellEditor',
      editable: isEditableField,
      ...getParamsForAgRichSelect(({ context }: $TSFixMe) => context.priorityOptions),
      keyCreator: agRichKeyCreator,
      filterParams: {
        debounceMs: 0,
        suppressAndOrCondition: true,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: priorityTextMatcher,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
    },
    {
      headerName: 'Start date',
      type: COLUMN_TYPE.generalDate,
      field: processItemFields.START_DATE,
      suppressSizeToFit: true,
      width: 100,
      editable: isEditableField,
      cellEditor: DateCellEditor,
      cellClass: `day1-date-cell ${CUSTOM_DATE_FORMAT}`,
      filter: 'agDateColumnFilter',
      valueFormatter: formatDate,
      comparator: dateComparator,
      cellEditorPopup: true,
      filterParams: {
        comparator: datesComparator,
        browserDatePicker: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
      cellEditorParams: ({ context }: $TSFixMe) => ({
        getMaxDate: (data: $TSFixMe) => getMaxDate(data[processItemFields.DUE_DATE], getMaxDateWithThreshold()),
        getMinDate: () => getMinDate(null, context.tenantCreationDate ? lastYear(context.tenantCreationDate) : null),
        canClearSelection: true,
      }),
    },
    {
      headerName: 'Due date',
      type: COLUMN_TYPE.generalDate,
      field: processItemFields.DUE_DATE,
      suppressSizeToFit: true,
      width: 100,
      editable: isEditableDueDate,
      cellEditor: DateCellEditor,
      cellEditorPopup: true,
      cellClassRules: {
        'day1-date-cell': 'true',
        'custom-date-format': 'true',
        'invalid-data': (params: $TSFixMe) => isAdvancedFinancials && !validateDueDate(params),
      },
      tooltipValueGetter: (params: $TSFixMe) =>
        isAdvancedFinancials && !validateDueDate(params)
          ? dueDateError({ dataDueDate: params.data.linkedInitiative.plannedL4Date })
          : null,
      filter: 'agDateColumnFilter',
      tooltipComponentParams: (params: $TSFixMe) => {
        return { theme: isAdvancedFinancials && !validateDueDate(params) ? tooltipTheme.ERROR : null, allowHTML: true }
      },
      valueFormatter: formatDate,
      comparator: dateComparator,
      filterParams: {
        comparator: datesComparator,
        browserDatePicker: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
      cellEditorParams: ({ context }: $TSFixMe) => ({
        getMinDate: (data: $TSFixMe) =>
          getMinDate(
            null,
            data[processItemFields.START_DATE] ||
              (context.tenantCreationDate ? lastYear(context.tenantCreationDate) : null),
          ),
        canClearSelection: true,
      }),
    },
    {
      headerName: 'Forecast due date',
      type: COLUMN_TYPE.generalDate,
      field: processItemFields.FORECAST_DATE,
      suppressSizeToFit: true,
      width: 120,
      cellEditor: DateCellEditor,
      cellEditorPopup: true,
      editable: isEditableField,
      valueFormatter: formatDate,
      comparator: dateComparator,
      filter: 'agDateColumnFilter',
      cellClass: CUSTOM_DATE_FORMAT,
      filterParams: {
        comparator: datesComparator,
        browserDatePicker: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
      cellEditorParams: ({ context }: $TSFixMe) => ({
        canClearSelection: true,
        getMinDate: () => getMinDate(null, context.tenantCreationDate ? lastYear(context.tenantCreationDate) : null),
      }),
    },
    {
      headerName: 'Confidential',
      field: processItemFields.CONFIDENTIAL,
      suppressSizeToFit: true,
      width: 90,
      ...getParamsForAgRichSelect(booleanYesNoOptions),
      editable: isEditableField,
      cellEditor: 'agRichSelectCellEditor',
      filter: 'agMultiColumnFilter',
      keyCreator: agSelectFilterValueFormatter(booleanYesNoOptions),
      filterParams: {
        debounceMs: 0,
        suppressAndOrCondition: true,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: confidentialTextMatcher,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
    },
    ...(isEntityUniqueIdEnabled
      ? [
          {
            headerName: 'Unique ID',
            field: processItemFields.UNIQUE_ID,
            valueGetter: (params: $TSFixMe) => {
              return params.data.uniqueIdentifier
            },
            suppressSizeToFit: true,
            width: 90,
            editable: false,
            filters: [
              {
                filter: 'agTextColumnFilter',
              },
              {
                filter: 'agSetColumnFilter',
              },
            ],
          },
        ]
      : []),
    getCategoryColumn({
      editable: isEditableField,
      filter: 'agMultiColumnFilter',
      filterParams: {
        debounceMs: 0,
        suppressAndOrCondition: true,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: categoryTextMatcher,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
    }),
    ...getCustomColumnsColumnDefs(customColumns, {
      editable: isEditableField,
      processCellCallback: customColumnProcessCellCallback,
      width: 100,
      minWidth: 100,
      flex: 1,
    }),
    ...additionalExportColumns,
  ]

  // Ensure that the columns are not editable until finished being created and they have an ID
  return addRowEditabilityCheck(columnDefs)
}
