import styled from 'styled-components'

import { Button, Text } from '@blueprintjs/core'
import { Popup, FONT_FAMILY, FONT_FAMILY_BOWER, white, black } from '@imo/imo-ui-toolkit'
import myimoLogo from '@assets/svg/myimo-logo.svg'
import { MediaSizes } from '@helpers/constants'

export const StyledContainer = styled.div`
  .terms-condition-overlay {
    background-color: unset;
  }
`

export const StyledContainerLogo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  align-items: flex-start;
  padding-top: 400px;
  padding-left: 100px;
  margin: 0;
  height: 100vh;
  font-family: 'McKinsey Sans', sans-serif;
  background-color: #ffffff;
`

export const StyledWelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30vh;
  margin: 0 auto;
  width: 86vw;
  height: 100vh;

  @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
    padding-top: 8vh;
    padding-left: 3vw;
  }
`

export const StyledLogoContainer = styled.div`
  margin-bottom: 3vh;
  align-self: flex-start;

  @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
    align-self: center;
  }
  img {
    height: 60px;
    width: auto;
  }
`

export const StyledWelcomeText = styled.p`
  align-self: flex-start;
  color: var(--BP-Gray-Light-gray5, #f6f7f9);
  font-family: 'McKinsey Sans', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  max-width: 45vw;

  @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
    align-self: center;
    max-width: unset;
  }
`

export const StyledHelloText = styled.h1`
  align-self: flex-start;
  font-family: ${FONT_FAMILY_BOWER}, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 46px;
  text-align: left;
  margin-top: 3px;
  min-width: 45vw;

  @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
    text-align: center;
  }
`

export const StyledMyIMOText = styled.div`
  align-self: flex-start;
  width: 45vw;

  @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
    width: unset;
  }

  p {
    font-family: ${FONT_FAMILY}, sans-serif;
    font-size: 16px;
    color: ${white};
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 35px;

    @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
      text-align: center;
    }
  }
`

export const StyledMyIMOButton = styled.div`
  align-self: flex-start;

  @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
    align-self: center;
  }

  button {
    border-radius: 2px;
    border: 1px solid var(--BP-Gray-Light-gray1, #d3d8de);
    background: var(--BP-Gray-Light-gray5, #f6f7f9);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  }
`

export const StyledLogoHeaderContainer = styled.div`
  position: relative;
  height: 100px;

  @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
    height: 70px;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url(${myimoLogo});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const StyledAreaContainer = styled.div`
  padding: 60px 40px 0 40px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const StyledBodyContainer = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  height: 74vh;

  @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
    height: 82vh;
  }
`

export const StyledAreaHeader = styled.div`
  color: ${black};
  padding-bottom: 24px;
  font-family: ${FONT_FAMILY_BOWER};
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
`

export const StyledTextarea = styled(Text)`
  white-space: break-spaces;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 16px;
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: unset;
  resize: none;
  ${(props) => props.customStyles}
  flex: 1;
`

export const StyledActionsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 100%;
  padding: 24px 40px 40px 40px;
  font-size: 12px;
  font-weight: 300;
  position: relative;
  position: relative;
  color: var(--BP-Gray-Black, #111418);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  .bp4-control-indicator {
    height: 20px;
    width: 20px;

    ::before {
      height: 1.3em;
      width: 1.3em;
    }
  }

  &.accept-block {
    font-family: ${FONT_FAMILY};
    font-weight: 300;
    padding: 20px 40px 0 40px;
  }

  &.pagination-action-block {
    position: relative;
    width: 100%;
  }

  .checkbox {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .bp4-control {
    margin-bottom: 0;
  }
`
export const CommonStyledButton = styled(Button)`
  position: absolute;
  font-size: 14px;
  padding: 8px 10px;
  min-height: 24px;
  ${(props) => props.customStyles}
`

export const StyledPopup = styled(Popup)`
  width: 49vw;
  overflow-y: auto;

  .popup-content {
    padding: 0;
  }

  &.terms-condition-popup,
  &.legal-disclaimer-popup {
    top: 8vh;
    max-height: unset;
    transform: translateX(-50%);
    background: transparent;
    border-radius: unset;

    @media ${MediaSizes.EXTRA_SMALL}, ${MediaSizes.SMALL}, ${MediaSizes.MEDIUM} {
      top: 2vh;
      width: 95vw;
    }
  }
`
