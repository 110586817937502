export const CUSTOM_DATE_FORMAT = 'custom-date-format'

const dayFormatProperty = {
  dataType: 'DateTime',
  numberFormat: {
    format: 'mmm d, yyyy',
  },
}

export const excelStyles = [
  {
    id: 'indexCell',
    dataType: 'string',
    alignment: {
      horizontal: 'Right',
      vertical: 'Bottom',
    },
  },
  {
    id: 'header',
    font: { bold: true },
  },
  {
    id: 'indent-1',
    alignment: { indent: 1 },
    dataType: 'string',
  },
  {
    id: 'indent-2',
    alignment: { indent: 2 },
    dataType: 'string',
  },
  {
    id: 'multiline_cell',
    alignment: { wrapText: true },
  },
  {
    id: 'custom-currency-format',
    numberFormat: {
      format: '0',
    },
  },
  {
    id: 'text-cell',
    dataType: 'string',
  },
  {
    id: CUSTOM_DATE_FORMAT,
    ...dayFormatProperty,
  },
  {
    id: `day1-date-cell ${CUSTOM_DATE_FORMAT}`,
    ...dayFormatProperty,
  },
  {
    id: `day1-date-cell ${CUSTOM_DATE_FORMAT} invalid-data`,
    ...dayFormatProperty,
  },
  { id: 'utc-timestamp', dataType: 'DateTime', numberFormat: { format: 'yyy-mm-dd hh:mm:ss UTC' } },
  { id: 'multiline-text', dataType: 'String', alignment: { wrapText: true } },
]

export const excelStylesGetter = ({ data }) => {
  if (data.taskId) {
    return {
      s: {
        alignment: {
          indent: 1,
        },
      },
    }
  }
}
