import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getSharedApi, getTeamWorkspaceApi } from '@common/net'
import { userInfo } from '@generic/selectors'
import { prepareUpdatedData } from '../../decisionLog/utils'
import {
  ADD_RISK_LOG_DATA,
  DELETE_RISK_LOG_DATA_PENDING,
  FETCH_CONTROL_TOWER_RISK_LOG_DATA,
  FETCH_RISK_LOG_DATA_PENDING,
  FETCH_TR_RISK_LOG_DATA,
  UPDATE_CONTROL_TOWER_RISK_LOG_DATA,
  UPDATE_RISK_LOG_DATA_PENDING,
  UPDATE_TR_RISK_LOG_DATA,
} from '../actions/actionTypes'
import {
  deleteRiskLogDataFail,
  deleteRiskLogDataSuccess,
  fetchControlTowerRiskLogDataSuccess,
  fetchRiskLogDataFail,
  fetchRiskLogDataSuccess,
  updateControlTowerRiskLogDataSuccess,
  updateRiskLogDataFail,
  updateRiskLogDataSuccess,
} from '../actions'
import { isNil, omit } from 'lodash'
import { getOr } from 'lodash/fp'
import { showWarningMessage } from '@domain/generic/actions/actions'
import { actionTypes } from '@common/notifications/utils'

export const fetchRiskLogData = createSaga(function* fetchRiskLogData({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()

  try {
    const data = yield call(
      api.request('getRisks', {
        query: {
          teamId: payload.query.teamId,
          historyDate: payload.query.historyDate,
          isFull: true,
        },
      }),
    )

    yield put(fetchRiskLogDataSuccess({ data }))
  } catch (e) {
    yield put(fetchRiskLogDataFail())
  }
})

export const updateCustomColumn = createSaga(function* updateCustomColumn({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { customColumn, data } = payload
  const column = data.customColumns.find((column: $TSFixMe) => column.columnName === customColumn)

  const body = {
    entityId: data.id,
    type: 'riskLogItem',
    optionValue: !isNil(column?.option?.id)
      ? getOr(null, ['option', 'id'], column)
      : getOr(null, ['option', 'name'], column),
  }

  const api = yield getSharedApi()

  try {
    yield call(
      api.request('updateCustomColumn', {
        query: {
          columnId: column.id,
        },
        body,
      }),
    )
  } catch (e) {
    const { status, message } = e as $TSFixMe
    if (status === 403) {
      yield put(
        showWarningMessage({
          actionType: actionTypes.VALIDATION_FAIL,
          errorMessage: message,
        }),
      )
    }
  }
})

export const updateRiskLogData = createSaga(function* updateRiskLogData({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { customColumn: customColumnName, data, body } = payload
  const api = yield getTeamWorkspaceApi()

  try {
    const column = customColumnName
      ? data.customColumns.find((column: $TSFixMe) => column.columnName === customColumnName)
      : undefined
    const customColumn = column
      ? {
          columnId: column.id,
          entityId: data.id,
          optionValue: !isNil(column?.option?.id)
            ? getOr(null, ['option', 'id'], column)
            : getOr(null, ['option', 'name'], column),
        }
      : null

    yield call(
      api.request('updateRisk', {
        query: {
          teamId: payload.query.teamId,
          riskId: payload.query.id,
        },
        body: {
          ...body,
          ...(customColumn ? { customColumn } : {}),
        },
      }),
    )
    yield call(fetchRiskLogData, { payload })

    const user = yield select(userInfo)

    yield put(
      updateRiskLogDataSuccess(
        prepareUpdatedData({
          id: payload.query.id,
          body: payload.body,
          user,
          sortOrder: ['status', 'dueDate'],
        }),
      ),
    )
  } catch (e) {
    yield put(updateRiskLogDataFail())
  }
})

export const fetchTrRiskLogData = createSaga(function* fetchTrRiskLogData(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()

  try {
    const data = yield call(api.request('getRisksForAllTeam'))

    yield put(fetchRiskLogDataSuccess({ data: { items: data, createdAt: null } }))
  } catch (e) {
    yield put(fetchRiskLogDataFail())
  }
})

export const updateTrRiskLogData = createSaga(function* updateTrRiskLogData({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { customColumn: customColumnName, data, body } = payload
  const api = yield getTeamWorkspaceApi()

  try {
    const column = customColumnName
      ? data.customColumns.find((column: $TSFixMe) => column.columnName === customColumnName)
      : undefined
    const customColumn = column
      ? {
          columnId: column.id,
          entityId: data.id,
          optionValue: !isNil(column?.option?.id)
            ? getOr(null, ['option', 'id'], column)
            : getOr(null, ['option', 'name'], column),
        }
      : null

    yield call(
      api.request('updateRisksForAllTeam', {
        query: {
          teamId: payload.query.teamId,
          riskId: payload.query.id,
        },
        body: {
          ...body,
          ...(customColumn ? { customColumn } : {}),
        },
      }),
    )

    yield call(fetchTrRiskLogData, { payload })

    const user = yield select(userInfo)

    yield put(
      updateRiskLogDataSuccess(
        prepareUpdatedData({
          id: payload.query.id,
          body: payload.body,
          user,
          sortOrder: ['status', 'team', 'dueDate'],
        }),
      ),
    )
  } catch (e) {
    yield put(updateRiskLogDataFail())
  }
})

export const createRiskLogData = createSaga(function* createRiskLogData({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  if (!data.isNew) {
    return
  }

  const api = yield getTeamWorkspaceApi()
  const response = yield call(api.request('createRisk', { query: { teamId }, body: omit(data, 'isNew', 'id', 'team') }))

  yield put(
    updateRiskLogDataSuccess({
      id: data.id,
      body: response,
      sortOrder: ['status', 'team', 'dueDate'],
    }),
  )
},
true)

export const deleteRiskLogData = createSaga(function* deleteRiskLogData({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const {
    teamId,
    data: { isNew, id: riskId },
  } = payload

  if (!isNew) {
    try {
      const api = yield getTeamWorkspaceApi()
      yield call(api.request('deleteRisk', { query: { teamId, riskId } }))
    } catch (e) {
      yield put(deleteRiskLogDataFail(e))
    }
  }

  yield put(deleteRiskLogDataSuccess(riskId))
})

export const fetchControlTowerRiskLogData = createSaga(function* fetchControlTowerRiskLogData(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamWorkspaceApi()

  const data = yield call(api.request('getControlTowerRisk'))

  yield put(fetchControlTowerRiskLogDataSuccess({ data: { items: data } }))
})

export const updateControlTowerRiskLogData = createSaga(function* updateControlTowerRiskLogData({
  payload,
}: {
  payload: $TSFixMe
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { customColumn: customColumnName, data, body } = payload
  const api = yield getTeamWorkspaceApi()

  const column = customColumnName
    ? data.customColumns.find((column: $TSFixMe) => column.columnName === customColumnName)
    : undefined
  const customColumn = column
    ? {
        columnId: column.id,
        entityId: data.id,
        optionValue: !isNil(column?.option?.id)
          ? getOr(null, ['option', 'id'], column)
          : getOr(null, ['option', 'name'], column),
      }
    : null

  yield call(
    api.request('updateControlTowerRisk', {
      query: {
        teamId: payload.query.teamId,
        riskId: payload.query.id,
      },
      body: {
        ...body,
        ...(customColumn ? { customColumn } : {}),
      },
    }),
  )

  yield call(fetchControlTowerRiskLogData, { payload })

  const user = yield select(userInfo)

  yield put(
    updateControlTowerRiskLogDataSuccess(
      prepareUpdatedData({
        id: payload.query.id,
        body: payload.body,
        user,
        sortOrder: ['status', 'team', 'dueDate'],
      }),
    ),
  )
})

export default function* RiskLogSaga() {
  yield all([
    takeLatest(FETCH_TR_RISK_LOG_DATA, fetchTrRiskLogData),
    takeLatest(UPDATE_TR_RISK_LOG_DATA, updateTrRiskLogData),
    takeLatest(FETCH_RISK_LOG_DATA_PENDING, fetchRiskLogData),
    takeLatest(UPDATE_RISK_LOG_DATA_PENDING, updateRiskLogData),
    takeLatest(ADD_RISK_LOG_DATA, createRiskLogData),
    takeLatest(DELETE_RISK_LOG_DATA_PENDING, deleteRiskLogData),
    takeLatest(FETCH_CONTROL_TOWER_RISK_LOG_DATA, fetchControlTowerRiskLogData),
    takeLatest(UPDATE_CONTROL_TOWER_RISK_LOG_DATA, updateControlTowerRiskLogData),
  ])
}
