import { combineActions, handleActions } from 'redux-actions'
import { updateData } from '../../decisionLog/utils'
import {
  FETCH_RISK_LOG_DATA_FAIL,
  FETCH_RISK_LOG_DATA_PENDING,
  FETCH_RISK_LOG_DATA_SUCCESS,
  FETCH_TR_RISK_LOG_DATA,
  ADD_RISK_LOG_DATA,
  DELETE_RISK_LOG_DATA_SUCCESS,
  UPDATE_RISK_LOG_DATA_FAIL,
  UPDATE_RISK_LOG_DATA_SUCCESS,
  FETCH_CONTROL_TOWER_RISK_LOG_DATA,
  UPDATE_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS,
  FETCH_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS,
} from '../actions/actionTypes'

export interface IRiskLogState {
  loading: boolean
  createdAt: Date | null
  items: $TSFixMe[]
}

export const initialState: IRiskLogState = {
  loading: false,
  createdAt: null,
  items: [],
}

const riskLogReducer = handleActions<IRiskLogState, $TSFixMe>(
  {
    [`${combineActions(FETCH_RISK_LOG_DATA_PENDING, FETCH_TR_RISK_LOG_DATA, FETCH_CONTROL_TOWER_RISK_LOG_DATA)}`]: (
      state,
    ) => ({
      ...state,
      loading: true,
    }),
    [`${combineActions(FETCH_RISK_LOG_DATA_SUCCESS, FETCH_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS)}`]: (
      state,
      { payload },
    ) => ({
      ...state,
      loading: false,
      createdAt: payload.data.createdAt,
      items: payload.data.items,
    }),
    [`${combineActions(UPDATE_RISK_LOG_DATA_SUCCESS, UPDATE_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS)}`]: (
      state,
      { payload: { id, body, sortOrder } },
    ) => ({
      ...state,
      items: updateData({ data: state.items, id, body, sortOrder }),
      loading: false,
    }),
    [`${combineActions(FETCH_RISK_LOG_DATA_FAIL, UPDATE_RISK_LOG_DATA_FAIL)}`]: (state) => ({
      ...state,
      loading: false,
    }),
    [ADD_RISK_LOG_DATA]: (state, { payload }) => {
      if (!payload.isNew) {
        return state
      }

      const { items } = state

      return {
        ...state,
        items: [...items, payload],
      }
    },
    [DELETE_RISK_LOG_DATA_SUCCESS]: (state, { payload: riskId }) => {
      const { items } = state

      return {
        ...state,
        items: items.filter((item) => item.id !== riskId),
      }
    },
  },
  initialState,
)

export default riskLogReducer
