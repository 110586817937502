import { handleActions } from 'redux-actions'
import * as constants from '@teamHome/actions/actionTypes'
import { get, isNil } from 'lodash'
import { loadingState } from '@imo/imo-ui-toolkit/dist/helpers/constants'
import { DayOneProjectListDto } from '@common/types/dtos/DayOneProjectListDto'
import { entityValueMap } from '@shared/OwnedBlock/utils/mapData'
import { EntityType, NotificationEntityTypes, SynergyTypes } from '@helpers/constants'

export type NotificationEventUser = {
  id: number
  displayName?: string
  surname: string
  name: string
  createdAt: string
  email: string
  firstTime: boolean
  hasRestrictedDomain: boolean
  isActive: boolean
  isAdmin: boolean
  lastLogin: string
  organization: string
  role: string
  umId: string
  updatedAt: string
}

export type MetadataTeam = {
  id?: number
  name?: string
}

export type NotificationEventMetadata = {
  entity: Record<string, any> // Currently a database model - BE response should be reworked
  currentValue?: unknown
  previousValue?: unknown
  deletedValue?: unknown
  added?: unknown
  removed?: unknown
  customColumn?: string
  delayedReason?: string
  changingReason?: string
  mirroredTeams?: string[]
  task?: {
    name: string
  }
  team?: MetadataTeam
  senderTeam?: MetadataTeam
  imoTeam?: MetadataTeam
}

export type NotificationApiAction = 'create' | 'update' | 'delete' | 'mirror' | 'unmirror'

export interface NotificationEvent {
  action: NotificationApiAction
  createdAt: string
  entityId: number
  entityType: EntityType
  field: string
  id: string
  metadata: NotificationEventMetadata
  teamId: number
  updatedAt: string
  user: NotificationEventUser | null // Null if triggered by cron
  userId: number
}

export interface NotificationEntityDetails {
  id: number
  type: EntityType
  name: string
  listId: string
  projectId: number
  isInterdependency?: boolean
  keyProcessId: number
  synergyType?: SynergyTypes
}

export type NotificationEntityRelationship = 'owner' | 'team-member'

export type NotificationEntity = {
  id: number
  total: number
  isWatching: boolean
  canUnwatch: boolean
  watchingForbidden: boolean
  entity: NotificationEntityDetails
  notificationEvents?: (NotificationEvent | NotificationEntity)[]
  entityNotificationEvents?: { total?: number; notificationEvents?: NotificationEvent[] }
  relationship?: NotificationEntityRelationship
}

export interface NotificationsState {
  status?: 'initial' | 'loading' | 'ready'
  inbox: NotificationEntity[]
  inboxTotal: number
  archived: NotificationEntity[]
  archivedTotal: number
  loading: Record<string, boolean>
}

export interface ITeamHomeState {
  teams: $TSFixMe[]
  inScopeKeyProcesses: $TSFixMe[]
  inScopeKeyProcessesEditPopup: {
    processes: $TSFixMe[]
    selected: $TSFixMe[]
  }
  instructions: $TSFixMe
  projectPlanList: DayOneProjectListDto
  isLockedProjectPlanList: boolean
  teamStatus: {
    currWeekStatus: string
    lastWeekStatus: string
    rationale: string
    escalations: string
    keyIssues: $TSFixMe[]
    updated: boolean
    updatedAt: string
    createdDate: string | null
  }
  deliverableTypesStatuses: $TSFixMe[]
  imoStatus: string
  teamProjectsStatuses: $TSFixMe
  otherTeamUpdates: $TSFixMe[]
  otherTeamUpdatesState: string
  flaggedRisksAndDecisions: $TSFixMe[]
  flaggedRisksAndDecisionsState: string
  keyProcessDeleteDetails: $TSFixMe[]
  keyProcessDeleteDetailsState: string
  notifications: NotificationsState
}

//TODO create separate reducers for each page (My team, Weekly status update etc.)
export const initialState = {
  teams: [],
  inScopeKeyProcesses: [],
  inScopeKeyProcessesEditPopup: {
    processes: [],
    selected: [],
  },
  instructions: {},
  projectPlanList: [],
  isLockedProjectPlanList: false,
  teamStatus: {
    currWeekStatus: '',
    lastWeekStatus: '',
    rationale: '',
    escalations: '',
    keyIssues: [],
    updated: false,
    updatedAt: '',
    createdDate: null,
  },
  deliverableTypesStatuses: [],
  imoStatus: '',
  teamProjectsStatuses: {},
  otherTeamUpdates: [],
  otherTeamUpdatesState: loadingState.INITIAL,
  flaggedRisksAndDecisions: [],
  flaggedRisksAndDecisionsState: loadingState.INITIAL,
  keyProcessDeleteDetails: [
    {
      project: [],
      tasks: [],
      subKeyProcesses: [],
      keyProcesses: [],
      allowedDeliverables: [],
    },
  ],
  keyProcessDeleteDetailsState: loadingState.INITIAL,
  notifications: {
    status: 'initial' as const,
    inbox: [],
    inboxTotal: 0,
    archived: [],
    archivedTotal: 0,
    loading: {},
  },
}

export const getEntityLoadStateKey = (entityType: string, entityId: number, archived: boolean) => {
  return `${entityType}-${entityId}-${archived ? 'archived' : 'unarchived'}`
}

export const TOP_LEVEL_LOADING_ID = 0 as const
export const TOP_LEVEL_LOADING_TYPE = 'TOP_LEVEL_NOTIFICATIONS' as const
export const TOP_LEVEL_LOADING_ARCHIVED = getEntityLoadStateKey(TOP_LEVEL_LOADING_TYPE, TOP_LEVEL_LOADING_ID, true)
export const TOP_LEVEL_LOADING_UNARCHIVED = getEntityLoadStateKey(TOP_LEVEL_LOADING_TYPE, TOP_LEVEL_LOADING_ID, false)

function transformUserNotifications(notifications: $TSFixMe[]) {
  return notifications.map(({ entityNotificationEvents: { total, notificationEvents }, ...entityNotification }) => ({
    ...entityNotification,
    total,
    notificationEvents,
  }))
}

/**
 * Top level notifications (e.g. project): filter out by type and id
 * Task notifications:
 * - if the task to remove is the only notifications for its parent element: remove the parent element and the task notification
 * - if the task to remove has siblings: filter tasks notifications by type and id
 * - if there are no tasks notifications to remove: return parent element and the task notifications
 */
const filterNotifications = (type: string, id: number) => {
  return (acc: Array<never | NotificationEntity>, curr: NotificationEntity) => {
    //filtering tasks notifications
    if (type === entityValueMap.Task && curr.notificationEvents) {
      const filteredNestedNotifications = curr.notificationEvents.filter((el) => {
        return !(type === (el as NotificationEntity)?.entity?.type && id === (el as NotificationEntity).entity?.id)
      })

      //if there are other nested notifications with the same parent, filter out by type and id
      if (curr.notificationEvents.length > 1 && filteredNestedNotifications.length) {
        // We need to reduce the total as we have archived the nested notification
        const numberArchived = curr.notificationEvents.length - filteredNestedNotifications.length

        // We remove the number we have archived from the existing total
        const newTotal = curr.total - numberArchived

        acc.push({
          ...curr,
          notificationEvents: filteredNestedNotifications,
          total: newTotal,
        })
      }
      //if there is nothing to filter out return parent element with all child notifications
      else if (curr.notificationEvents.length === filteredNestedNotifications.length) {
        acc.push(curr)
      }
    }
    //filtering top level notifications
    else if (!(type === curr.entity?.type && id === curr.entity?.id)) {
      acc.push(curr)
    }
    return acc
  }
}

/**
 *  Take the fetched notifications and update the notification tree for archived or inbox
 *  appropriately with the new notifications
 */
function appendNotifications({
  fetchedNotifications,
  currentNotifications,
  id,
  entityType,
}: {
  fetchedNotifications: NotificationEntity[] | NotificationEvent[]
  currentNotifications: NotificationEntity[]
  id: number
  entityType: EntityType
}): NotificationEntity[] {
  // This case is for a specific node entity, hence the id check
  if (id) {
    // This is a special case for tasks as they are nested in projects
    if (
      entityType === NotificationEntityTypes.TeamKeyProcessL2ProjectTask &&
      currentNotifications[0].entity && // TODO: Remove these two lines when OutgoingInterdependency notifications are created
      !currentNotifications[0].entity.isInterdependency
    ) {
      // Find the project index and the nested task index
      let projectNotificationIndex = -1
      let taskNotificationIndex = -1
      for (let i = 0; i < currentNotifications.length; i++) {
        const notification = currentNotifications[i]

        if (
          notification?.entity?.type === NotificationEntityTypes.TeamKeyProcessL2Project &&
          notification.notificationEvents
        ) {
          const taskIndex = notification.notificationEvents.findIndex((event: any) => {
            return event.entity?.type === NotificationEntityTypes.TeamKeyProcessL2ProjectTask && event.entity?.id === id
          })

          // The task has been found the task
          if (taskIndex > -1) {
            projectNotificationIndex = i
            taskNotificationIndex = taskIndex
            break
          }
        }
      }

      // Project and the child task have been found
      if (projectNotificationIndex > -1 && taskNotificationIndex > -1) {
        const notifications = currentNotifications[projectNotificationIndex].notificationEvents

        if (notifications) {
          const task: NotificationEntity = notifications[taskNotificationIndex] as NotificationEntity

          // We create a new task entity and update it with
          // the new notification events we have loaded in
          const newTaskEntity: NotificationEntity = { ...task }

          newTaskEntity.entityNotificationEvents = {
            ...newTaskEntity.entityNotificationEvents,
            notificationEvents: (newTaskEntity.entityNotificationEvents?.notificationEvents || []).concat(
              fetchedNotifications as NotificationEvent[],
            ),
          }

          notifications[taskNotificationIndex] = newTaskEntity
        }
      }
    } else {
      // This is the general case for all other top level entities, projects, initiatives etc
      const entityIndex = currentNotifications.findIndex((entity) => entity?.entity?.id === id)

      const newEntity: NotificationEntity = { ...currentNotifications[entityIndex] }
      newEntity.notificationEvents = newEntity.notificationEvents?.concat(fetchedNotifications)
      currentNotifications[entityIndex] = newEntity
    }
  } else {
    currentNotifications = [...currentNotifications, ...transformUserNotifications(fetchedNotifications)]
  }

  return currentNotifications
}

const teamHome = handleActions<ITeamHomeState, $TSFixMe>(
  {
    [constants.SET_PROJECT_PLAN_LIST]: (state, action) => {
      const { projectPlanList } = action.payload

      return {
        ...state,
        projectPlanList: isNil(projectPlanList) ? [] : projectPlanList,
        isLockedProjectPlanList: isNil(projectPlanList),
      }
    },

    [constants.RESET_PROJECT_PLAN_LIST]: (state) => ({ ...state, projectPlanList: [], isLockedProjectPlanList: false }),

    [constants.APPEND_INBOX_NOTIFICATIONS]: (state, action) => {
      const { id, inbox, entityType } = action.payload as {
        id: number
        inbox: NotificationEntity[]
        entityType: EntityType
        inboxTotal: number
      }

      const newInbox = appendNotifications({
        id,
        entityType,
        currentNotifications: [...state.notifications.inbox],
        fetchedNotifications: inbox,
      })

      return {
        ...state,
        notifications: {
          ...state.notifications,
          status: 'ready',
          inbox: newInbox,
        },
      }
    },

    [constants.APPEND_ARCHIVE_NOTIFICATIONS]: (state, action) => {
      const { id, archived, entityType } = action.payload as {
        id: number
        archived: NotificationEntity[]
        entityType: EntityType
        archivedTotal: number
      }

      const newArchived = appendNotifications({
        id,
        entityType,
        currentNotifications: [...state.notifications.archived],
        fetchedNotifications: archived,
      })

      return {
        ...state,
        notifications: {
          ...state.notifications,
          archived: newArchived,
        },
      }
    },

    [constants.UPDATE_NOTIFICATION_WATCH_STATUS]: (state, action) => {
      const { entityId, entityType, isWatching } = action.payload

      const updateWatchStatus = (notification: NotificationEntity): NotificationEntity => {
        let watching = notification.isWatching

        if (notification.entity?.type === entityType && notification.entity?.id === entityId) {
          watching = isWatching
        }

        const events = notification.notificationEvents?.map((n) =>
          'notificationEvents' in n || 'entityNotificationEvents' in n ? updateWatchStatus(n) : n,
        )

        return {
          ...notification,
          notificationEvents: events,
          isWatching: watching,
        }
      }

      const inbox = state.notifications.inbox.map(updateWatchStatus)
      const archived = state.notifications.archived.map(updateWatchStatus)

      return {
        ...state,
        notifications: {
          ...state.notifications,
          archived,
          inbox,
        },
      }
    },

    // Set a loading flag
    [constants.FETCH_NOTIFICATIONS]: (state) => {
      return {
        ...state,
        notifications: { ...state.notifications, status: 'loading' },
      }
    },

    [constants.SET_NOTIFICATIONS]: (state, action) => {
      const { inbox, archived, inboxTotal, archivedTotal } = action.payload

      const newInbox = inbox ? transformUserNotifications(inbox) : state.notifications.inbox
      const newInboxTotal = inbox ? inboxTotal : state.notifications.inbox

      const newArchived = archived ? transformUserNotifications(archived) : state.notifications.archived
      const newArchivedTotal = archived ? archivedTotal : state.notifications.archivedTotal

      return {
        ...state,
        notifications: {
          ...state.notifications,
          status: 'ready',
          inbox: newInbox,
          inboxTotal: newInboxTotal,
          archived: newArchived,
          archivedTotal: newArchivedTotal,
        },
      }
    },

    [constants.REMOVE_NOTIFICATIONS_FROM_INBOX]: (state, action) => {
      const { id, type } = action.payload ?? {}
      const inbox: NotificationEntity[] = [...state.notifications.inbox]

      const inboxFiltered: NotificationEntity[] = id
        ? inbox.reduce(filterNotifications(type, id), [] as Array<never | NotificationEntity>)
        : []
      const inboxRemovedCount = id ? inbox.length - inboxFiltered.length : inbox.length

      const newInboxTotal = state.notifications.inboxTotal
        ? state.notifications.inboxTotal - inboxRemovedCount
        : state.notifications.inboxTotal

      return {
        ...state,
        notifications: {
          ...state.notifications,
          inbox: inboxFiltered,
          inboxTotal: newInboxTotal,
        },
      }
    },

    [constants.REMOVE_NOTIFICATIONS_FROM_ARCHIVE]: (state, action) => {
      const { id, type } = action.payload ?? {}
      const archived: NotificationEntity[] = [...state.notifications.archived]

      const archivedFiltered: NotificationEntity[] = id
        ? archived.reduce(filterNotifications(type, id), [] as Array<never | NotificationEntity>)
        : []
      const archivedRemovedCount = id ? archived.length - archivedFiltered.length : archived.length

      const newArchivedTotal = state.notifications.archivedTotal
        ? state.notifications.archivedTotal - archivedRemovedCount
        : state.notifications.archivedTotal

      return {
        ...state,
        notifications: {
          ...state.notifications,
          archived: archivedFiltered,
          archivedTotal: newArchivedTotal,
        },
      }
    },

    [constants.ADD_NOTIFICATION_TO_LOADING]: (state, action) => {
      const { id, type, archived } = action.payload ?? {}
      const updatedLoading = { ...state.notifications.loading }
      const key = getEntityLoadStateKey(type, id, archived)
      updatedLoading[key] = true

      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: updatedLoading,
        },
      }
    },

    [constants.REMOVE_NOTIFICATION_FROM_LOADING]: (state, action) => {
      const { id, type, archived } = action.payload ?? {}
      const updatedLoading = { ...state.notifications.loading }
      const key = getEntityLoadStateKey(type, id, archived)
      delete updatedLoading[key]

      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: updatedLoading,
        },
      }
    },

    [constants.SET_TEAM_WEEKLY_STATUS_DATA]: (state, action) => {
      const { teamStatus } = state
      const { current, lastWeek, updated, createdDate } = action.payload
      const { name, rationale, escalations, updatedAt } = current

      return {
        ...state,
        teamStatus: {
          ...teamStatus,
          currWeekStatus: name,
          lastWeekStatus: get(lastWeek, 'name', null),
          updatedAt,
          escalations,
          rationale,
          updated,
          createdDate,
        },
      }
    },

    [constants.SET_KEY_ISSUES]: (state, action) => {
      const { teamStatus } = state

      return {
        ...state,
        teamStatus: {
          ...teamStatus,
          keyIssues: action.payload,
        },
      }
    },

    [constants.SET_DELIVERABLE_TYPES_STATUSES]: (state, action) => {
      const { imoStatus, deliverableTypes } = action.payload

      return {
        ...state,
        deliverableTypesStatuses: deliverableTypes,
        imoStatus,
      }
    },

    [constants.SET_TEAM_PROJECTS_STATUSES]: (state, action) => ({
      ...state,
      teamProjectsStatuses: { ...(action.payload || {}) },
    }),

    [constants.SET_OTHER_TEAM_UPDATES]: (state, action) => {
      return {
        ...state,
        otherTeamUpdates: action.payload,
      }
    },

    [constants.CREATE_OTHER_TEAM_UPDATE_SUCCESS]: (state, action) => {
      const { data } = action.payload
      const { otherTeamUpdates } = state

      return {
        ...state,
        otherTeamUpdates: [...otherTeamUpdates, data],
      }
    },

    [constants.UPDATE_OTHER_TEAM_UPDATE_SUCCESS]: (state, action) => {
      const { data, id } = action.payload

      return {
        ...state,
        otherTeamUpdates: state.otherTeamUpdates.map((request) =>
          request.id === id ? { ...request, ...data } : request,
        ),
      }
    },

    [constants.DELETE_OTHER_TEAM_UPDATE_SUCCESS]: (state, action) => {
      const { id } = action.payload

      return {
        ...state,
        otherTeamUpdates: state.otherTeamUpdates.filter((contact) => contact.id !== id),
      }
    },

    [constants.SET_OTHER_TEAM_UPDATES_DATA_STATE]: (state, action) => ({
      ...state,
      otherTeamUpdatesState: action.payload,
    }),

    [constants.SET_FLAGGED_RISKS_AND_DECISIONS]: (state, action) => {
      return {
        ...state,
        flaggedRisksAndDecisions: action.payload,
      }
    },

    [constants.SET_FLAGGED_RISKS_AND_DECISIONS_DATA_STATE]: (state, action) => ({
      ...state,
      flaggedRisksAndDecisionsState: action.payload,
    }),

    [constants.SUBMIT_WEEKLY_STATUS_UPDATE_SUCCESS]: (state, action) => {
      const { teamStatus } = state
      const { updatedAt } = action.payload

      return {
        ...state,
        teamStatus: {
          ...teamStatus,
          updatedAt,
          updated: true,
        },
      }
    },

    [constants.SET_KEY_PROCESS_DELETE_DETAILS]: (state, action) => ({
      ...state,
      keyProcessDeleteDetails: action.payload,
    }),

    [constants.SET_KEY_PROCESS_DELETE_DETAILS]: (state, action) => ({
      ...state,
      keyProcessDeleteDetails: action.payload,
    }),
    [constants.SET_KEY_PROCESS_DELETE_DETAILS_STATE]: (state, { payload }) => ({
      ...state,
      keyProcessDeleteDetailsState: payload,
    }),
  },
  initialState,
)

export default teamHome
